<template>
  <div>
    <van-nav-bar :title="title" left-text="返回" fixed left-arrow @click-left="goBack"  />
    <van-form @submit="onSubmit" >
      <van-tabs v-if="editId" class="tab" color="#1073e5" v-model="activeName" style="margin-bottom: 10px" @change="handleClick">
        <van-tab title="设备" name="1"></van-tab>
        <van-tab title="备件" name="2">
         <div class="pm-content">
           <van-cell>
             <van-swipe-cell>
               <van-row type="flex">
                 <van-col span="20">备件名称</van-col>
                 <van-col span="3"><p style="color: #666666">型号</p></van-col>
               </van-row>
             </van-swipe-cell>
           </van-cell>
          <van-cell v-for="item in partsList" :key="item.id" style="margin-top: 4px">
            <van-swipe-cell>
              <van-row type="flex">
                <van-col span="16">{{ item.name }}</van-col>
                <van-col span="8">{{ item.spec }}</van-col>
              </van-row>
            </van-swipe-cell>
          </van-cell>
         </div>
        </van-tab>
        <van-tab title="知识" name="3">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh(activeName)">
            <van-collapse v-model="activeKnowledge">
           <div class="knowledge">
             <van-collapse-item v-for="(item, index) in knowledge_list" :title="item.name" :key="index" :name="index + 1">
               <div v-for="(i, kIndex) in item.list" :title="i.name" :key="kIndex" :name="kIndex + 1">
                 <div class="knowledge-url" v-for="(is,sIndex) in i.knowledges" :key="sIndex">
                   <a v-if="is.base_url === 1" :href="host + is.path" target="_blank"><p>{{ is.name.split('/')[is.name.split('/').length - 1] }}</p></a>
                   <a v-if="is.base_url === 2" :href="is.path" target="_blank"><p>{{ is.name.split('/')[is.name.split('/').length - 1]}}</p></a>
                 </div>
               </div>
             </van-collapse-item>
           </div>
          </van-collapse>
          </van-pull-refresh>
        </van-tab>
        <van-tab title="故障树" name="4">

        </van-tab>
      </van-tabs>
      <div v-if="tabs === '1'" class="bq-form">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh(activeName)">
          <van-field v-model="data.code" name="编码" label="资产编号:" readonly />
          <van-field v-model="data.name" name="名称" label="设备名称:" readonly />
          <van-field v-model="data.factory_id" name="工厂" label="工厂:" readonly />
          <van-field v-model="data.workshop_id" name="车间" label="车间:" readonly />
          <van-field v-model="data.line_id" name="生产线" label="生产线:" readonly />
          <van-field v-model="data.station_id" name="工位" label="工位:" readonly />
          <van-field v-model="data.location" name="存放位置" label="存放位置:" readonly />
          <van-field v-model="data.primary_category_id" name="一级分类" label="一级分类:" readonly />
          <van-field v-model="data.secondary_category_id" name="二级分类" label="二级分类:" readonly />
          <van-field v-model="data.parent_id" name="上级设备" label="上级设备:" readonly />
          <van-field v-model="data.supplier_id" name="供应商" label="供应商:" readonly />
          <van-field v-model="data.manufacturer_id" name="制造商" label="制造商:" readonly />
          <van-field v-model="data.operater_id" name="使用岗位" label="使用岗位:" readonly />
          <van-field v-model="data.brand" name="品牌" label="品牌:" readonly />
          <van-field v-model="data.model" name="型号" label="型号:" readonly />
          <van-field v-model="data.factory_number" name="出厂编号" label="出厂编号:" readonly />
          <van-field v-model="data.manufacturing_date" name="生产日期" label="生产日期:" readonly />
          <van-field v-model="data.purchase_date" name="购置日期" label="购置日期:" readonly />
          <van-field v-model="data.guarantee_period" name="质保期" label="质保期:" readonly />
          <van-field v-model="data.purchase_price" name="购置价格" label="购置价格:" readonly />
          <van-field v-model="data.original_value" name="原值" label="原值:" readonly />
          <van-field v-model="data.power" name="功率" label="功率:" readonly />
          <van-field v-model="data.motors" name="电机数" label="电机数:" readonly />
          <van-field v-model="criticality" name="设备类别" label="设备类别:" readonly />
          <van-field v-model="nature" name="资产性质" label="资产性质:" readonly />
          <van-field v-model="status" name="状态" label="状态:" readonly />
          <van-field v-model="is_outsource" name="委外" label="委外:" readonly />
          <van-field v-model="is_hb" name="环保设备" label="环保设备:" readonly />
          <van-field v-model="is_special" name="特种设备" label="特种设备:" readonly />
          <van-field v-model="data.sort" name="排序" label="排序:" readonly />
        </van-pull-refresh>
      </div>
      <div v-if="tabs === '3'" class="tree">
        <div>
          <div class="box_tree">
            <div class="title_tree">专业点检指导书</div>
            <a href="https://beiqi.aiemtc.com/knowledge/cs/zywh/%E6%9C%BA%E5%99%A8%E4%BA%BA%E9%99%84%E5%B1%9E%E7%84%8A%E6%8E%A5%E7%B3%BB%E7%BB%9F.jpg" target="_blank">机械指导书</a>
          </div>
          <div class="box_tree">
            <div class="title_tree">专业维护指导书</div>
            <a href="https://beiqi.aiemtc.com/knowledge/cs/zywh/%E6%9C%BA%E5%99%A8%E4%BA%BA%E9%99%84%E5%B1%9E%E7%84%8A%E6%8E%A5%E7%B3%BB%E7%BB%9F.jpg" target="_blank">机械指导书</a>
          </div>
          <div class="box_tree">
            <div class="title_tree">专业点检培训视频</div>
            <a href="http://beiqioss.aiemtc.com/video/cs/zywh/6%E8%BD%B4%E6%9C%BA%E5%99%A8%E4%BA%BA%E6%9C%AC%E4%BD%93.mp4" target="_blank">焊接机器人6轴.mp4</a>
          </div>
          <div class="box_tree">
            <div class="title_tree">专业维护培训视频</div>
            <a href="http://beiqioss.aiemtc.com/video/cs/zywh/6%E8%BD%B4%E6%9C%BA%E5%99%A8%E4%BA%BA%E6%9C%AC%E4%BD%93.mp4" target="_blank">机器人附属焊接系统.mp4</a>
          </div>
        </div>
      </div>
      <div v-if="tabs === '4'" class="tree">
        <div>
          <div class="box_tree">
            <div class="title_tree">机械臂 维修次数：1次</div>
            <div class="reason_tree"><span>原因:</span>电子元件失效：主要原因是电子器件的损坏或者老化，或者是电源电压不稳定等。</div>
            <div class="way"><span>解决措施:</span>我们应该尽可能的选择高品质的电子元件，同时平时定期维护机械手，以避免元件损坏。另外，还应及时替换受损的电子器件，并检查电电源电压的稳定性，确保机械手运行稳定。</div>
            <a href="http://m2.hx-im.com.cn/workorder/cm/edit/14367" target="_blank">工单详情>></a>
          </div>
          <div class="box_tree">
            <div class="title_tree">基座 维修次数：1次</div>
            <div class="reason_tree"><span>原因:</span>电压异常的：电路故障可能会导致不能正常充电或者电量严重不足，甚至可能会损坏机器人的电子元器件。</div>
            <div class="way"><span>解决措施:</span>检查基座电路板上是否有烧焦、脱落、接触不良等情况，如果有，需要修复或更换电路板。</div>
            <a href="http://m2.hx-im.com.cn/workorder/cm/edit/14366" target="_blank">工单详情>></a>
          </div>
          <div class="box_tree">
            <div class="title_tree">关节模组 维修次数：1次</div>
            <div class="reason_tree"><span>原因:</span>由于液压和气动系统中出现泄漏或损坏所致</div>
            <div class="way"><span>解决措施:</span>检查液压和气动系统中的管路和连接，寻找泄漏的位置，并及时修复或更换损坏的部件。</div>
            <a href="http://m2.hx-im.com.cn/workorder/cm/edit/14366" target="_blank">工单详情>></a>
          </div>
        </div>
      </div>

    </van-form>
  </div>
</template>

<script>
import API from '@/api/asset/equipment'
export default {
  inject: ['goUrl', 'isEdit', 'add', 'update', 'reload'],
  data(){
    return{
      data: {
      },
      editId: null,
      activeName:1,
      tabs: '1',
      activeKnowledge: ['1'],
      knowledge_list: [],
      criticalityList: ['A', 'B', 'C', 'D'],
      natureList: ['生产设备', '生产设备', '动力设施', '其他'],
      criticality: '',
      nature: '',
      status: '',
      is_outsource: '',
      is_hb: '',
      is_special: '',
      parts_list: null,
      isLoading: false,
      partsList: [
        {
          name: '急停按钮开关',
          spec: 'XB4-BS8445'
        },
        {
          name: '蓝色带灯按钮开关',
          spec: 'XB4-BW36B5（ZB4BWOB65 ZB4BW363）'
        },
        {
          name: '握杆',
          spec: 'XB4-BHOC002522-B'
        }
      ],
      activeNames: '1',
      host: 'http://beiqi.aiemtc.com'
    }
  },
  created() {
    const edit = this.isEdit()
    if (edit) {
      this.title = '设备详情'
      this.editId = edit - 0
      this.info()
    } else {
      this.title = '新增设备'
    }

  },
  methods:{
    async info() {
      const res = await API.getOne(this.editId)
      this.data = res.data
      this.criticality = this.criticalityList[res.data.criticality]
      this.nature = this.natureList[res.data.criticality]
    },
    goBack() {
      this.goUrl('/asset')
    },
    handleClick(name) {
      this.tabs = name
    },
    onRefresh(activeName) {
      this.info()
      this.activeName = activeName
      setTimeout(() => {
        this.isLoading = false;
      }, 1000)
    },
    onSubmit(values) {
      if (values) {
        if (this.editId) {
          this.update(API, this.data, '更新成功', '/asset')
        } else {
          this.add(API, this.data, '新增成功')
        }
      } else {
        console.log('error submit!!')
        return false
      }
      console.log('submit', values);
    }
  }
}
</script>

<style scoped>
  .knowledge{
    width: 96%;
    margin: 8px auto;
  }
  .knowledge-url{
    padding: 20px;
  }
  .tree{
    margin: 80px auto 80px;
    overflow-y: scroll;
    height: 100vh;
  }
  .box_tree{
    width: 97%;
    margin: 10px auto 10px;
    background-color: #fff;
    border-radius: 5% ;
    padding: 15px;
  }
  .title_tree{
   font-size: 20px;
    margin-bottom: 10px;
  }
  .reason_tree{
    font-size: 15px;
    line-height: 1.5;
  }
  .reason_tree span{
    font-size: 18px;
    line-height: 1.5;
    color: #0086b3;
  }
  .way{
    font-size: 15px;
    margin-top: 10px;
    line-height: 1.5;
  }
  .way span{
    font-size: 18px;
    color: #0086b3;
    line-height: 1.5;
  }
  .box_tree a{
    color: #0086b3;
    font-size: 18px;
  }
  .air{
    width: 100%;
    height: 50px;
    margin-top: 20px;
  }
</style>
